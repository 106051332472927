import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["plan", "submit"]
  static classes = ["selected"]
  static values = {
    selected: String,
  }

  selectedValueChanged(productId) {
    this.planTargets.forEach((target) => {
      const isSelected = productId === target.dataset.plansProductIdParam
      target.classList.toggle(this.selectedClass, isSelected)
    })

    this.submitTarget.dataset.productId = productId
  }

  select({ params: { productId } }) {
    this.selectedValue = productId
  }
}
