import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["analysis", "home", "settings", "tab", "frame"]

  static values = {
    tab: { type: String, default: "home" },
  }

  static classes = ["active"]

  select({ params: { tab } }) {
    this.tabValue = tab
  }

  tabValueChanged(newTab) {
    this.tabTargets.forEach((tab) => {
      tab.classList.toggle(
        this.activeClass,
        tab.dataset.tabsTabParam === newTab,
      )
    })
    this.analysisTarget.classList.toggle("hidden", newTab !== "analysis")
    this.homeTarget.classList.toggle("hidden", newTab !== "home")
    this.settingsTarget.classList.toggle("hidden", newTab !== "settings")
    // reload the analysis tab if it is selected
    if (newTab === "analysis") {
      this.reloadActiveFrame()
    }
  }

  reloadAllWhenSubmitWasSuccessful({ detail: { success } }) {
    if (success) {
      this.frameTargets.forEach((frame) => frame.reload())
    }
  }

  reloadActiveWhenSubmitWasSuccessful({ detail: { success } }) {
    if (success) {
      this.reloadActiveFrame()
    }
  }

  reloadActiveFrame() {
    this.activeFrame.reload()
  }

  get activeTab() {
    return this.tabTargets.find(
      (target) => target.dataset.tabsTabParam === this.tabValue,
    )
  }

  get activeFrame() {
    return this[`${this.activeTab.dataset.tabsTabParam}Target`].querySelector(
      "turbo-frame",
    )
  }
}
