import { BridgeComponent } from "@hotwired/strada"
import { Turbo } from "@hotwired/turbo-rails"
import { post } from "@rails/request.js"

export default class extends BridgeComponent {
  static component = "purchase"

  buy() {
    // console.log("buy")
    this.send("buy", this.payload, this.buyCallback.bind(this))
  }

  restoreSubscription() {
    this.send("restore")
  }

  manageSubscription() {
    this.send("manage")
  }

  get payload() {
    return { product_id: this.element.dataset.productId }
  }

  async buyCallback() {
    // console.log("buyCallback", this.payload)
    // the purchase has successfully completed, so we could give (permanently) access

    const result = await post("/payment", {
      body: this.payload,
      contentType: "application/json",
    })
    if (result.ok) Turbo.visit("/")
  }
}
